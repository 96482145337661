import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" version="1.0" width="500.000000pt" height="500.000000pt" viewBox="0 0 500.000000 500.000000" preserveAspectRatio="xMidYMid meet">
    <title>Loader Logo</title>
    <g id="B" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
    <path d="M1530 3995 l0 -220 860 -860 c473 -473 860 -865 860 -870 0 -6 50 -60 110 -120 l110 -110 0 215 0 215 118 120 117 120 -3 863 -2 862 -155 0 -155 0 0 -937 0 -938 -820 820 c-451 451 -820 825 -820 830 0 6 -49 60 -110 120 l-110 110 0 -220z"/>
    <path d="M3800 3395 c0 -448 3 -815 8 -815 4 0 52 45 107 100 55 55 103 100 108 100 4 0 7 -326 5 -725 l-3 -724 43 41 42 42 0 730 0 730 -38 -37 c-21 -20 -43 -37 -50 -37 -9 0 -12 161 -12 705 l0 705 -105 0 -105 0 0 -815z"/>
    <path d="M4120 3553 l0 -658 115 115 116 115 -3 -737 -3 -737 43 41 42 42 0 1238 0 1238 -155 0 -155 0 0 -657z"/>
    <path d="M1253 3948 l-42 -43 -3 -738 c-2 -405 -1 -737 2 -737 3 0 23 18 45 40 21 21 42 37 47 34 4 -3 8 -390 8 -860 l0 -854 155 0 155 0 0 938 0 937 818 -821 c449 -451 865 -872 924 -935 l107 -114 0 220 0 220 -969 972 -970 972 0 -222 0 -222 -108 -108 c-59 -59 -111 -107 -115 -107 -4 0 -8 331 -9 735 l-3 736 -42 -43z"/>
    <path d="M942 3627 l-42 -43 0 -682 0 -682 39 0 40 0 3 723 c2 397 3 723 2 724 0 2 -19 -17 -42 -40z"/>
    <path d="M1530 3477 l0 -152 970 -970 970 -970 -2 152 -3 152 -967 969 -968 970 0 -151z"/>
    <path d="M627 3312 l-47 -48 0 -1237 0 -1237 155 0 155 0 0 657 0 658 -110 -110 -111 -110 3 738 c2 405 3 737 3 737 -1 0 -22 -22 -48 -48z"/>
    <path d="M3757 2522 l-47 -48 0 -734 0 -734 44 43 45 44 3 736 c2 405 3 737 2 738 0 2 -21 -19 -47 -45z"/>
    <path d="M1100 2313 l-105 -108 3 -707 2 -708 105 0 105 0 0 815 c0 448 -1 815 -3 815 -1 0 -50 -48 -107 -107z"/>
    <path d="M900 2168 l0 -42 40 39 c22 21 40 40 40 42 0 2 -18 3 -40 3 l-40 0 0 -42z"/>
    </g>
  </svg>
);

export default IconLoader;
